import { makeAutoObservable } from "mobx"
import { rootStore          } from "./RootStore"

import { getControllerState, queryControllerState, setControllerCommand       } from "api/controller"
import { TControllerState, TStateRequestResult, TTarget } from "models/ControllerState"

import { toast             } from "react-toastify"
import { errorHandler      } from "utils/errorHandler"
import {  STATE_TIMER      } from "constants/globalConstants"
import { controlPanelStore } from "./ControlPanelStore"

class ControllerStateStore {

   loading  = false
   idProject: number
   location : string
   state    : TControllerState[] = []
   intervals: {
      [k: string]: ReturnType <typeof setTimeout>
   } = {}

   constructor () { makeAutoObservable (this) }

   getState (idProject: number)
   {
      this.setLoading (true)

      if (this.idProject != idProject) {

         this.idProject = idProject
         Object.values (this.intervals)
         .forEach (interval => clearInterval (interval))

         this.state = []
      }

      if (controlPanelStore.aliases.length > 0) {

         controlPanelStore.aliases.forEach (alias => {
            if (alias) this.getStateCommand (this.idProject, alias)
         })
      } else this.setLoading (false)
   }

   setState (controllerState: TStateRequestResult)
   {
      if (controllerState?.code == 0) {

         /* controllerState.data.forEach (control => {

            const idx = this.state.flatMap (({ name }) => name).indexOf (control.name)

            if (idx === -1) this.state.push   (control)
            else            this.state.splice (idx, 1, control)

            this.state = this.state.slice ()
         }) */

         this.state = controllerState.data
      } else errorHandler (controllerState.message)

      if (Object.keys (this.intervals).length == 0) {

         controlPanelStore.aliases.forEach (alias => {

            if (alias)
               this.intervals[alias] = setInterval (async () => {

                  if (rootStore.currentUserStore.currentUserData &&
                      this.idProject != null) {
                     this.queryStateCommand (this.idProject, alias)
                  }
                  else Object.values (this.intervals)
                  .forEach (interval => clearInterval (interval))

               }, (STATE_TIMER))
         })
      }

      this.setLoading (false)
   }

   async getStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await getControllerState (idProject, alias)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async queryStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await queryControllerState (idProject, alias)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async setCommand (
      project: number,
      alias  : string,
      command: number,
      value? : number | TTarget,
   ) {

      const result = await setControllerCommand (
         project,
         alias,
         command, 
         value
      )
      
      if (result.Code == 0) toast.success (result.Message)
      else                  toast.error   (result.Message)

      this.queryStateCommand (project, alias)
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading
   }

   setLocation (location: string)
   {
      this.location = location
   }
}

export const controllerStateStore = new ControllerStateStore ()