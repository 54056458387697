import { useCallback, useState } from "react"
import { setControl            } from "components/Library/ControlFabric/hooks/setControl"
import { controlPanelStore     } from "store/ControlPanelStore"
import { setTarget } from "components/Library/ControlFabric/hooks/setTarget"

export const useWaitCommand = (sendCommand: (command?: number, value?: number) => void) => {

   const [wait, setWait] = useState (false)

   const controlCommand = useCallback (async (command?: number, value?: number) => {

      sendCommand (command, value)

      if (controlPanelStore.getControlByCommand         (command) ||
          controlPanelStore.getIndicationBlockByCommand (command)) {
         setControl ({
            name: controlPanelStore.getControlByCommand         (command)?.id
            ??    controlPanelStore.getIndicationBlockByCommand (command)?.id,
            command: command,
            value  : value
         }, setWait)
      }
      else if (controlPanelStore.getTargetSchedulesByCommand (command)) {
         setTarget ({
            name   : controlPanelStore.getTargetSchedulesByCommand (command)?.id,
            command: command,
            value  : value
         }, setWait)
      }
   }, [controlPanelStore.controlBlocks, controlPanelStore.targetBlocks])

   return { controlCommand, wait }
}