import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"

import { rootStore    } from "store/RootStore"
import { TAuthRequest } from "models/Auth"
import { useNavigate  } from "react-router-dom"

import { Key             } from "grommet-icons"
import { HeaderComponent } from "components/Common/Header"
import { InputPhone      } from "./components/InputPhone"
import { toast           } from "react-toastify"
import {
   Form,
   FormField,
   Anchor,
   Button,
   Box,
   TextInput,
   Text,
   Heading
} from "grommet"

import { IS_LOCAL } from "constants/globalConstants"
import { controllerAbort, controllerReinit } from "utils/abortController"
import { ConfirmModal } from "./components/ConfirmModal"

//import { maskPhone, phoneValidation } from './utils/FormValidation';

export const ResetPassword = observer (() => {

   const [phone   , setPhone   ] = useState ('')
   const [password, setPassword] = useState ('')
   const [confirm , setСonfirm ] = useState (false)
   const nav = useNavigate ()

   const submitResetForm  = async (data: TAuthRequest) => {

      try {
         const result = await rootStore.authStore.resetpassword (data)

         if (result && !result?.error_text) {
            toast.success ('Ваш пароль обновлён и теперь вы можете авторизоваться')
            nav ("/login")
         }
      }
      catch { setСonfirm (false) }
   }

   useEffect (() => {

      if (IS_LOCAL && confirm) {

         submitResetForm ({
            phone,
            password
         })

         const interval = setInterval (() => {

            submitResetForm ({
               phone,
               password
            })

            setTimeout (() => {
               controllerAbort  ()
               controllerReinit ()
            }, 900)

         }, 1000)

         return () => clearInterval (interval)
      }
   }, [confirm])

   return (

      <div className = "main-layout">

         <HeaderComponent />
         <>
            <Box
               fill
               align   = "center"
               justify = "center"
               margin  = { { top: "large" } }
            >

               <Heading
                  level  = { 2 }
                  margin = "none"
               >
                  Сброс пароля
               </Heading>

               <Box
                  width  = "medium"
                  margin = "large"
                  pad    = "small"
               >
                  <Form
                     validate  = "blur"
                     value     = { {
                        phone   : phone,
                        password: password
                     } }
                     name      = "basic"
                     className = "form"
                     messages  = { {
                        required: 'Это поле должно быть заполнено',
                     } }
                  >

                     <InputPhone
                        phone    = { phone }
                        setPhone = { setPhone }
                     />

                     <FormField
                        label     = "Пароль"
                        name      = "password"
                        className = "form__input"
                        required  = { { indicator: false } }
                        htmlFor   = "password-sign-in"
                     >
                        <TextInput
                           reverse
                           id   = "password-sign-in"
                           name = "password"
                           type = "password"
                           placeholder = "Введите пароль"
                           icon = { <Key /> }
                           onChange  = { e => setPassword (e.target.value) }
                        />
                     </FormField>

                     <Text
                        size      = "small"
                        textAlign = 'center'
                        as        = 'div'
                     >
                        { IS_LOCAL 
                          ? 'Для подтверждения необходимо нажать кнопку на контроллере'
                          : 'Вы получите SMS с кодом на указанный номер телефона'
                        }
                     </Text>

                     <Box
                        direction = "row"
                        justify   = "between"
                        margin    = { { vertical: "medium" } }
                     >
                        <Button
                           primary
                           fill
                           label   = { IS_LOCAL ? 'Сбросить пароль' : 'Получить SMS' }
                           justify = "center"
                           onClick = { () => setСonfirm (true) }
                        />
                     </Box>

                  </Form>

                  <Box
                     align  = "center"
                     margin = { { top: 'small' } }
                  >
                     <Anchor
                        label = "Авторизация"
                        href  = "/login"
                     />
                  </Box>

                  <ConfirmModal
                     phone      = { phone }
                     password   = { password }
                     confirm    = { confirm }
                     setСonfirm = { setСonfirm }
                     submitForm = { submitResetForm } 
                  />

               </Box>
            </Box>
         </>
      </div>
   )
})
