import React, { useState, useEffect } from 'react'
import { observer             } from 'mobx-react'

import { TSample              } from 'models/Sample'
import { TTag                 } from 'models/Tag'

import { controllerStateStore } from 'store/ControllerStateStore'
import { devicesStore         } from 'store/DevicesStore'
import { sampleStore          } from 'store/SampleStore'

import { Loader               } from 'components/Common/Loader'
import { Modal                } from 'components/Library/Modal/Modal'
import { TagChart             } from './components/TagChart'
import { Box, Heading, Text   } from 'grommet'
import { TIndicationBlock     } from 'models/ControlPanel'

export const TagBlock = observer (( { block } : { block: TIndicationBlock }) => {

   const [sample, setSample] = useState <TSample> ()
   const [modal , setModal ] = useState <boolean> ()
   const [tag              ] = useState <TTag> (devicesStore.tags
   .find (tag => tag.id == Number (block.id) && tag.alias == block.alias))

   useEffect (() => { 

      if (tag && sampleStore.samples.length > 0)
         setSample (sampleStore.samples.find (({ id_tag }) => id_tag == tag.id_tag))
   }, [sampleStore.samples])

   return (

      <Box
         round      = "small"
         pad        = "xsmall"
         background = "brand"
         flex       = 'grow'
         onClick    = { () => setModal (true) }
      >
         <Heading
            level     = { 3 }
            textAlign = "center"
            margin    = { { top: 'xsmall', bottom: 'none' } }
            weight    = 'lighter' 
            fill
         >
            { block?.label ?? tag?.name }
         </Heading>

         { controllerStateStore.state?.length > 0
            ?  sample
               ? sample.error_text
                  ? (
                     <Box
                        align  = "center"
                        margin = { { vertical: "xsmall" } }
                        style  = { { textAlign: 'center' } }
                        fill
                     >
                        { sample.error_text }
                     </Box>
                  ) : (
                     <Box fill>
                        <Text
                           weight    = "bold"
                           textAlign = "center"
                           size      = "2xl"
                           margin    = { { vertical: "xsmall" } }
                        >
                           { sample?.sample }{ tag?.sign && ' ' + tag?.sign }
                        </Text>
                     </Box>
               ) : (
                  <Box 
                     align  = "center"
                     margin = { { vertical: "small" } }
                  >
                     Показания не найдены
                  </Box>
               )
            : controllerStateStore.loading 
               ? <Loader size = 'medium' />
               : (
                  <Box 
                     align  = "center"
                     margin = { { vertical: "small" } }
                  >
                     Контроллер не подключен
                  </Box>
               )
         }

         <Modal
            modal    = { modal }
            setModal = { setModal }
            heading  = { 'Показания параметра "' + (block?.label ?? tag?.name) + '"' }
         >
            <TagChart 
               tag = { tag }
            />
         </Modal>
      </Box>

   )
})