import React, { useMemo } from 'react'

import { Box, Button          } from 'grommet'
import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { observer             } from 'mobx-react'
import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

export const ControlButton = observer (({
   control,
   sendCommand,
   watchdog
}: {
   control    : TControl,
   sendCommand: (command: number, value?: number) => void,
   watchdog   : boolean
}) => {

   const stat = useMemo (() => controllerStateStore?.state
   ?.find (state => state.name == control.id),
   [controllerStateStore?.state, control])

   const option = control.options
   ?.find (option => option?.value == stat?.value)

   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   if (control) return (

      <ControlFabric
         key  = { control.id }
         wait = { wait }
      >
         <Box
            direction = 'row'
            flex      = 'grow'
            wrap
         >
            <Button
               size      = "large"
               primary   = { option?.state != undefined ? !!option?.state : true }
               label     = { label ?? option?.state ? (option?.labelOn || option?.label) : (option?.labelOff || option?.label) } 
               onClick   = { () => watchdog ? controlCommand (option?.command) : sendCommand (option?.command) }
               fill      = 'horizontal'
               disabled  = { disabled }
               className = { className }
               style     = { {
                  ...style,
                  borderRadius: 0,
                  pointerEvents: option?.command ? 'auto' : 'none'
               } }
            />
         </Box>
      </ControlFabric>
   )
})
