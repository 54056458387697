import React from 'react'
import { observer } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { RadioButtonGroup     } from 'grommet'

import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'
import { controllerStateStore } from 'store/ControllerStateStore'

export const ControlRadioGroup = observer (({
   control,
   sendCommand,
   watchdog
} : {
   control    : TControl,
   sendCommand: (c: number, v?: number) => void,
   watchdog   : boolean
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   return (
      <ControlFabric
         wait = { wait }
         key  = { control.id }
      >
         <RadioButtonGroup
            name    = 'radio'
            options =  { control.options.map (option => (
               {
                  id   : String (option.command),
                  label: 
                        <span
                           key   = { option.command }
                           style = { { textAlign: 'right' } }
                        >
                           { label ?? option.label }
                        </span>,
                  value: control.look ? eval (control.look)(option.state) : option.state,
                  disabled: disabled,
               }
            )) }
            value     = { controllerStateStore.state
            ?.find (state => control.id == state.name)?.value }
            onChange  = { event => {
               watchdog 
               ? controlCommand (control.options
               .find (option => option.state == Number (event.target.value))?.command)
               : sendCommand (control.options
               .find (option => option.state == Number (event.target.value))?.command)
            } }
            style     = { style }
            className = { className }
            direction = 'row'
         />
      </ControlFabric>

   )
})