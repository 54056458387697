import React, { useContext } from 'react'
import { observer } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControl             } from 'models/ControlPanel'

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { CheckBox, ResponsiveContext } from 'grommet'

import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'

export const ControlToggle = observer (({
   control,
   sendCommand,
   watchdog
}: {
   control    : TControl,
   sendCommand: (c: number, v?: number) => void,
   watchdog   : boolean
}) => {

   const option = control.options
   .find (option => option.state == controllerStateStore?.state
   .find (state => state.name == control.id)?.value)

   const size = useContext (ResponsiveContext)
   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   if (option) return (

      <ControlFabric
         wait = { wait }
      >
         <CheckBox
            label     = { label ?? size != 'small' ? <span style = { { textAlign: 'right' } }>{ option.label }</span> : '' }
            checked   = { control.look ? eval (control.look) (option.state) : !!option.state }
            onChange  = { () => watchdog ? controlCommand (option.command) : sendCommand (option.command) }
            disabled  = { disabled }
            style     = { style }
            className = { className }
            reverse
            toggle
         />
      </ControlFabric>
   )
})