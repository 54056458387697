import { controllerStateStore } from "store/ControllerStateStore"
import { controlPanelStore    } from "store/ControlPanelStore"
import { toast                } from "react-toastify"

type TControlValue = {
   name   : string,
   command: number,
   value  : number
}

export const setTarget = (
   control: TControlValue,
   setWait: React.Dispatch<React.SetStateAction<boolean>>
) => {

   if (control) {

      const value = control?.value ??
      controlPanelStore.getTargetSchedulesOptionByCommand (control?.command)?.state

      const id    = controlPanelStore.targetSchedules
      .find    (block => block.controls
      .flatMap (control => control.options)
      .find    (option => option.command == control?.command))?.id

      let timer = true
      setWait (true)

      setTimeout (() => timer = false, 40000)

      const toastId    = toast.loading ('Ждём ответа контроллера')
      const intervalId = setInterval   (() => {

         const stateValue = controllerStateStore.state.find (state => state.name == id)?.value

         //console.log (control)
         //console.log (stateValue)

         if (stateValue != undefined) {

            if ((controlPanelStore.getTargetSchedulesByCommand (control.command)?.type === 'Toogle' && stateValue != value) ||
                (controlPanelStore.getTargetSchedulesByCommand (control.command)?.type !== 'Toogle' && stateValue == value)) {
               toast.update (toastId, { render: "Значение изменено", type: "success", isLoading: false, autoClose: 1000 })
               setWait (false)
               return clearInterval (intervalId)
            }
            if (timer === false) {

               setWait (false)
               toast.update (toastId, { render: "Контроллер не изменил значение", type: "error", isLoading: false, autoClose: 1000 })
               return clearInterval (intervalId)
            }
         }
         else {
            setWait (false)
            toast.update (toastId, { render: "Состояние параметра не отпределено", type: "error", isLoading: false, autoClose: 2000 })
            return clearInterval (intervalId)
         }
      }, 100)
   }
}